import React from "react";

export default function Resume() {
  return (
    <div className="p-8 font-sans text-sm leading-relaxed text-gray-700">
      <div className="mx-auto max-w-4xl">
        <header className="my-4">
          <h1 className="mb-2 text-3xl font-bold">Donnie Ashok</h1>
          <p className="text-xl">Senior DevOps Engineer</p>
          <p className="space-x-2">
            <span>
              <a
                href="https://github.com/Donnie"
                className="text-blue-600 transition-colors duration-300 hover:text-blue-800"
              >
                Github
              </a>
            </span>
            <span>|</span>
            <span>
              <a
                href="https://www.linkedin.com/in/donnieask/"
                className="text-blue-600 transition-colors duration-300 hover:text-blue-800"
              >
                LinkedIn
              </a>
            </span>
            <span>|</span>
            <span>
              <a
                href="https://donnie.in"
                className="text-blue-600 transition-colors duration-300 hover:text-blue-800"
              >
                donnie.in
              </a>
            </span>
            <span>|</span>
            <span>jobs@donnie.in</span>
            <span>|</span>
            <span>Berlin, Germany / Remote / (Open to Relocation)</span>
            <span>|</span>
            <span>2024-11-01</span>
          </p>
        </header>

        <section className="mb-4">
          <h2 className="mb-2 text-xl font-semibold">Summary</h2>
          <p>
            Senior DevOps Engineer bringing over a decade of expertise in CI/CD,
            hyperscaling, networking, GitOps, SecOps, and MLOps. I leverage
            cutting-edge technologies with a problem-solving mindset to drive
            innovation balanced with cost efficiency. I offer mentorship and
            training to help teams adopt sustainable DevOps practices.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="mb-2 text-xl font-semibold">Core Competencies</h2>
          <ul className="list-none  leading-tight">
            <li>
              <span className="font-semibold">Cloud Platforms:</span> AWS, GCP,
              Cloudflare, Digital Ocean, Linode, Proxmox on Bare metal
            </li>
            <li>
              <span className="font-semibold">
                Containerization & Orchestration:
              </span>{" "}
              Kubernetes (GKE/AWS), AWS ECS, AWS Fargate, GCP Cloud Run
            </li>
            <li>
              <span className="font-semibold">Infrastructure as Code:</span>{" "}
              Terragrunt, Terraform, Pulumi, Ansible, Argo CD, Crossplane
            </li>
            <li>
              <span className="font-semibold">Observability:</span> Datadog,
              Prometheus, Grafana Stack, ELK Stack, New Relic, AWS CloudWatch,
              GCP Cloud Monitoring
            </li>
            <li>
              <span className="font-semibold">CI/CD and automations:</span>{" "}
              Github Actions, Argo Workflows, Jenkins, GCP Cloud Build
            </li>
            <li>
              <span className="font-semibold">Networking:</span> Cloudflare
              Tunnel, Tailscale, Wireguard, VPC, Subnets, AWS SG, GCP Firewalls,
              NAT Gateways, Self Signed SSL
            </li>
            <li>
              <span className="font-semibold">MLOps, Data Science:</span>{" "}
              QDrant, Neo4j, Elasticsearch, MongoDB, MySQL, Postgres, Redis,
              Redshift, AWS Glue, AWS DMS
            </li>
            <li>
              <span className="font-semibold">Backend:</span> Golang, Python,
              Typescript, Node.js, Dart, Rust, PHP
            </li>
            <li>
              <span className="font-semibold">Frontend:</span> React, Angular,
              Next.js, Flutter
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="mb-2 text-xl font-semibold">Work Experience</h2>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              Senior DevOps Engineer - Moonfare GmbH
            </h3>
            <time className="italic">July 2024 - Present</time>
            <ul className="mt-2 list-disc pl-5 leading-tight">
              <li>
                Mentoring and guiding feature teams on efficient architecture
                and usage of Cloud resources.
              </li>
              <li>
                Wrote Golang controllers to automate unused resource detection
                and deletion, in cost optimisation efforts
              </li>
              <li>
                Deployed Cross Account Cross Cluster Argo CD with OIDC IAM auth
                reducing management clusters from 3 to 1
              </li>
              <li>
                Automated security scans in all clusters using Wiz for container
                vulnerability assessment
              </li>
              <li>
                Implemented ETL Pipeline using DMS, Redshift, S3 (Parquet) with
                Sandboxing
              </li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              Site Reliability Engineer - Moonfare GmbH
            </h3>
            <time className="italic">July 2022 - June 2024</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>Reduced AWS Bill by 60% for FY 2022/2023</li>
              <li>Recreated entire infrastructure for ISO 27001 Compliance</li>
              <li>
                Setup monitoring using New Relic for infrastructure and
                application metrics
              </li>
              <li>
                Implemented dynamic scaling and spot node provisioning using
                Karpenter
              </li>
              <li>
                Built dynamic preview environments to decrease Mean Time To
                Recovery and increase deployment frequency
              </li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              Software Development Engineer - Moonfare GmbH
            </h3>
            <time className="italic">July 2021 - June 2022</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>
                Built and maintained customer-facing web application and backend
                services using Typescript with React and Node.js
              </li>
              <li>
                Implemented in-app PDF parsing which was run at scale for
                thousands of PDFs per minute
              </li>
              <li>
                Rewrote Dockerfile of a few containers to speed up build and
                deploy phase
              </li>
              <li>Wrote healthchecks for apps with critical workflows</li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              Full Stack Developer - Medigo GmbH
            </h3>
            <time className="italic">December 2018 - June 2021</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>Implemented frontend designs, backend services, and APIs</li>
              <li>Managed CI/CD lifecycle with Jenkins and AWS ECS</li>
              <li>Worked on security hardening on Cloudflare and AWS VPC</li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              Technology Manager - Intelligent Legal Risk Management Solutions
            </h3>
            <time className="italic">May 2017 - November 2018</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>
                Created the first backend of LawSikho.com with online
                application process and payment gateway
              </li>
              <li>
                Built and managed the first engineering team of four engineers
              </li>
              <li>
                Developed real-time internal dashboard with Angular 7 and PHP
              </li>
              <li>
                Deployed the iPleaders Blog with High Availability, it continues
                to receive over 1.5m visits per month
              </li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">Founder - Legalify</h3>
            <time className="italic">May 2011 - April 2017</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>
                Specialized in comprehensive and regulatory compliant web
                solutions for the Indian Legal industry
              </li>
              <li>
                Deployed self-hosted secure and private email infrastructure for
                law firms
              </li>
              <li>
                Focused on tailored and scalable WordPress hosting and
                development
              </li>
            </ul>
          </article>
        </section>

        <div className="flex space-x-6">
          <section className="mb-4 w-2/3">
            <h2 className="mb-2 text-xl font-semibold">Education</h2>
            <h3 className="text-lg font-semibold">
              B.A. LL.B - Gujarat National Law University
            </h3>
            <time className="italic">June 2012 - May 2017</time>
            <p>
              Information Technology Law, International Trade Law and
              Competition Law
            </p>
          </section>

          <section className="w-1/2">
            <h2 className="mb-2 text-xl font-semibold">Certification</h2>
            <p>
              <a
                href="https://www.credly.com/badges/2760d15e-6afa-4fe3-a164-04297272a77c"
                className="text-blue-600 transition-colors duration-300 hover:text-blue-800"
              >
                CKAD from The Linux Foundation
              </a>
            </p>
            <h2 className="mt-1 mb-2 text-xl font-semibold">Languages</h2>
            <p>English, German (B2), Hindi, Bengali</p>
          </section>
        </div>
      </div>
    </div>
  );
}
